import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

import ArrowDown from 'icons/arrow-down.svg';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { useCloseOnOutsideClick } from 'utilities';
import {
  ACADEMY_STUDENTS_LANDING_LITHUANIA,
  ACADEMY_STUDENTS_LANDING_POLAND,
  ACADEMY_KIDS_LANDING,
} from 'shared/routes';

const AcademiesButton = ({ isMobileNavOpen }) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  const [isDropdownOpen, setDropdownState] = useState(false);
  const dropdownWrapperRef = useRef(null);
  const dropdownStateLabel = isDropdownOpen
    ? translation['navigation.dropdown.hide']
    : translation['navigation.dropdown.show'];
  const toggleDropdown = () => setDropdownState(!isDropdownOpen);

  useCloseOnOutsideClick(dropdownWrapperRef, toggleDropdown, isDropdownOpen);

  return (
    <div
      className={classNames(
        'header__nav-item header__nav-item--button header__nav-item--have-sub',
        {
          'open-dropdown': isDropdownOpen && !isMobileNavOpen,
        }
      )}
      onMouseEnter={isDropdownOpen ? null : toggleDropdown}
      onMouseLeave={isDropdownOpen ? toggleDropdown : null}
      ref={dropdownWrapperRef}
    >
      <button
        type="button"
        className="header__dropdown-button"
        onKeyPress={toggleDropdown}
      >
        <span className="header__nav-item-text">
          {translation['navigation.academy']}
        </span>
        <ArrowDown />
        <span className="sr-only">{dropdownStateLabel}</span>
      </button>
      <div className="header__dropdown-list-wrapper inverted">
        <ul className="header__dropdown-list">
          <li className="header__dropdown-item">
            <Link
              to={ACADEMY_STUDENTS_LANDING_LITHUANIA.en}
              className="header__dropdown-link"
            >
              {translation['navigation.academy.students.Lithuania']}
            </Link>
          </li>
          <li className="header__dropdown-item">
            <Link
              to={ACADEMY_STUDENTS_LANDING_POLAND.en}
              className="header__dropdown-link"
            >
              {translation['navigation.academy.students.Poland']}
            </Link>
          </li>
          <li className="header__dropdown-item">
            <Link
              to={ACADEMY_KIDS_LANDING.en}
              className="header__dropdown-link"
            >
              {translation['navigation.academy.kids']}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

AcademiesButton.propTypes = {
  isMobileNavOpen: PropTypes.bool,
};

export { AcademiesButton };
