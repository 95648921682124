import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import './scss/media-gallery.scss';
import generateUniqueId from 'utilities/generateUniqueId';

const MediaGallery = ({ title, videoId, images, className }) => {
  const imageThumbs = () =>
    images.slice(1).map((image) => (
      <div className="media-gallery__column" key={generateUniqueId()}>
        <div className="media-gallery__tile">
          <Img
            className="media-gallery__img"
            fluid={image.children[0].fluid}
            alt=""
          />
        </div>
      </div>
    ));

  return (
    videoId &&
    images &&
    images.length > 0 && (
      <div className={`media-gallery ${className || ''}`}>
        <div className="media-gallery__frame">
          <h2 className="media-gallery__title">{title}</h2>
          <div className="media-gallery__row">
            <div className="media-gallery__column media-gallery__column--double">
              <div className="media-gallery__tile">
                {images[0] && (
                  <Img
                    className="media-gallery__img"
                    fluid={images[0].children[0].fluid}
                    alt=""
                  />
                )}
              </div>
            </div>
            {imageThumbs()}
          </div>
        </div>
      </div>
    )
  );
};

MediaGallery.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  videoId: PropTypes.string.isRequired,
  images: PropTypes.oneOfType([PropTypes.array]),
};

export { MediaGallery };
