import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { generateUniqueId } from 'utilities';
import { AcademyContactsWithAddress } from 'components/academyContacts';
import { AcademyApplyCard } from 'components/academyApplyCard';
import { GlobalStateContext } from 'containers/GlobalContextProvider';

const ApplyFooter = ({
  applicationDeadline,
  applicationLinks,
  title,
  academyLocations,
  contact,
  locations,
  trademark,
}) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;
  const isFrontEndAcademy =
    title === 'Academy for Front-End' || title === 'Front-End akademija';

  return (
    <div className="apply-footer" id="registration">
      <div className="apply-footer__frame">
        {!isFrontEndAcademy && (
          <div>
            <h3 className="apply-footer__title">
              {`${translation['button.applyTo']} ${title}`}
            </h3>
            {academyLocations && academyLocations.length > 0 && (
              <div className="apply-footer__row">
                {academyLocations.map((academyLocation) => (
                  <div
                    className="apply-footer__column"
                    key={generateUniqueId()}
                  >
                    <AcademyApplyCard
                      academyLocation={academyLocation}
                      applicationDeadline={applicationDeadline}
                      applicationLink={
                        applicationLinks[`${academyLocation.toLowerCase()}`]
                      }
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <AcademyContactsWithAddress
          email={contact.email}
          locations={locations}
          trademark={trademark}
        />
      </div>
    </div>
  );
};

ApplyFooter.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      postalCode: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
    })
  ),
  contact: PropTypes.shape({
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }),
  academyLocations: PropTypes.arrayOf(PropTypes.string.isRequired),
  title: PropTypes.string.isRequired,
  applicationDeadline: PropTypes.string,
  applicationLinks: PropTypes.shape({
    kaunas: PropTypes.string.isRequired,
    vilnius: PropTypes.string.isRequired,
  }),
  trademark: PropTypes.string.isRequired,
};

export { ApplyFooter };
